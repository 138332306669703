.card-loisirs2 {
  width: 280px;
  height: 520px;
  margin-top: 20px;
  border-radius: 20px;
  background: rgb(0, 74, 173);
  background: linear-gradient(
    180deg,
    rgba(0, 74, 173, 1) 36%,
    rgba(203, 108, 230, 1) 100%
  );
  display: flex;
  flex-direction: column;
  border: white solid 2px;
}

.figure-card-loisirs2 {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
}
.card-loisirs2-img {
  width: 100%;
}
.card-loisirs2:hover .card-image {
  transform: scale(1.2); /* Zoom de 1.2 au survol */
  transition: transform 0.3s ease; /* Ajoute une transition fluide */
}
.card-image {
  width: 100%;
  border-radius: 20px;
}

.article-body {
  text-align: start;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.box-title-card-loisirs2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.title-card-loisirs2 {
  text-align: center;
  text-transform: uppercase;
  padding-top: 10px;
  margin: 0;
}

.trait-loisirs {
  width: 140px;
}

.content-card-loisirs2 {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
