.section_about {
  width: 100%;
  height: auto;
  background-color: #161631;
  padding-bottom: 30px;
}

.picture_about {
  width: 100%;
  display: flex;
  justify-content: center;
}

.img_picture_about {
  width: 50%;
  border-radius: 100%;
  margin-right: 20px;
  max-width: 265px;
  transition: transform 1.2s;
}

.text_about {
  width: 80%;
  margin: auto;
  color: #eeee;
  padding-top: 5%;
  font-size: large;
}

.box_button_flex {
  display: flex;
  justify-content: center;
  --animate-duration: 10s;
}

@media (min-width: 900px) {
  .box_body_about {
    display: flex;
    align-items: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .img_picture_about:hover {
    transform: rotate(360deg) scale(1.5);
  }
}
