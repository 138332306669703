.section_home {
  width: 100%;
  height: 100vh;
  background-image: url("../../ressources/images/background/home-mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

.box_home {
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.title_home {
  display: flex;
  justify-content: center;
  font-size: 25px;
  text-align: center;
  text-shadow: 3px 3px black;
}
.box_home_text_button {
  width: 80%;
  align-self: center;
}
.text_home {
  font-style: italic;
  font-size: larger;
  text-align: justify;
}

@media (min-width: 500px) {
  .section_home {
    width: 100%;
    height: 100vh;
    background-image: url("../../ressources/images/background/home-desktop.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}


