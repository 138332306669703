.button {
    height: 60px;
    display: flex;
}

.a_link {
    width: 120px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px;

    text-decoration: none;
    border-radius: 20px;
    background-color: #fff;
    color: black;
    align-self: flex-end;
    text-align: center;

}