.menu-desktop {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.menu-desktop-links {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
}

.link-reseaux {
  width: 10%;
  margin-left: 20px;
  display: flex;
  justify-content: space-evenly;
}
.logo-navbar-desktop {
  width: 30px;
  height: 30px;
}
.menu-desktop-links li {
  text-shadow: 1px 3px 3px black;
}

.menu-desktop-links li:last-child {
  width: 15%;
  border: 2px solid #8dd1ed;
  border-radius: 20px;
  padding: 10px 10px 10px 10px;
  text-align: center;
}

.li-navbar:not(:last-child):hover {
  border-bottom: 2px solid #8dd1ed;
}
