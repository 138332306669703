.section_secret_santa {
  background-color: #161631;
  color: antiquewhite;
  width: 100%;
  min-height: 100vh;
  height: auto;
}
.box-back-btn-title {
  display: flex;
}

.title_santa {
  align-self: center;
  text-align: center;
  flex-grow: 4;
  font-size: 28px;
}
.box-text {
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.boxs-form-lists {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.box-all-input {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.inputs-part-and-conj {
  width: 90%;
}

.input-part {
  display: flex;
  justify-content: space-between;
}
.input-part:first-child {
  margin-bottom: 10px;
}

.box-check-add-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.box-btns {
  display: flex;
  flex-direction: column;
}

.btn-tirage {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

/* Exemple https://getcssscan.com/css-checkboxes-examples?ref=beautifulbuttons-bottom */

/* Button Ajouter  */
.btn-add-part {
  width: 130px;
  height: fit-content;
  align-self: center;
  background-color: #2ea44f;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 6px 16px;
}

.btn-add-part:hover {
  background-color: #2c974b;
}

/* Bouton Tirage */
.button-4 {
  cursor: pointer;
  appearance: none;
  background-color: #fafbfc;
  border: 1px solid rgba(27, 31, 35, 0.15);
  border-radius: 6px;
  box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
    rgba(255, 255, 255, 0.25) 0 1px 0 inset;
  box-sizing: border-box;
  color: #24292e;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  list-style: none;
  padding: 6px 16px;
  position: relative;
  transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  white-space: nowrap;
  word-wrap: break-word;
}

.boxs-lists-santa {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.box-list-santa {
  width: 100%;
}
.box-img-btns {
  display: flex;
}
.img-santa {
  width: 100px;
}
@media (min-width: 700px) {
  .inputs-part-and-conj {
    width: 50%;
  }
  .boxs-lists-santa {
    flex-direction: row;
    justify-content: space-between;
  }

  .img-santa {
    width: 150px;
  }
}
