.section_portfolio {
  background-image: url("../../ressources/images/background/others-desktop.webp");
  background-position: left;
  background-size: cover;
  height: auto;
  padding-bottom: 30px;
}

.boxs-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.box-portfolio {
  margin-top: 30px;
  margin-bottom: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 1.2s;
  border-radius: 20px;
}

.img-portfolio {
  width: 150px;
  height: 150px;
  border-radius: 20px;
  transition: transform 1.2s;
}

.img-portfolio:hover {
  transform: scale(1.1);
  border: solid 1px white;
}

.texte-img-portfolio {
  color: white;
  margin-top: 5px;
}

.link_notion {
  color: white;
  text-align: center;
  width:80%;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 700px) {
  .box-portfolio {
    width: 25%;
  }
}
