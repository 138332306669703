.section_loisirs {
  background-color: #161631;
  color: white;
  height: auto;
  min-height: 100vh;
  width: 100%;
  padding-bottom: 30px;
}

.titre-loisirs {
  font-size: 40px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
}
.card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  font-size: large;
  font-style: italic;
  text-align: center;
}
.box,
.reversed-box {
  height: 15%;
  width: 95%;
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
  align-self: center;
  align-items: center;
  flex-direction: row;
}

.reversed-box {
  flex-direction: row-reverse;
}

.image-box {
  width: 150px;
  max-height: 150px;
  padding-right: 20px;
}
.p-card-loisirs {
  margin-top: 3px;
}
