.box_title_section {
    height: auto;
    padding-bottom: 50px;
    padding-top: 100px;
}

.title {
    margin: 0;
    font-size: 40px;
    text-align: start;
    padding-right: 90px;
    color: #fff;
    margin-left: 30px;
}

.line_box {
    position: relative;
    width: 75%;
    margin-left: 30px;
}

.line_title {
    position: absolute;
    border: solid 3px #fff;
    width: 100%;
    border-radius: 20px;
    margin-top: 25px;
}

.line_title_up {
    border: solid 3px #8DD1ED;
}

.width-0 {
    width: 0%
}
.width-25 {
    width: 25%
}
.width-50 {
    width: 50%
}
.width-75 {
    width: 75%
}
.width-100 {
    width: 100%
}