.line {
    align-self: center;
    border: solid 3px #fff;
    width: 50%;
    height: 0px;
  }
  .button_icon {
    background-color: transparent;
    border: none;
    width: 20%;
    height: 50px;
  }
  .menuItem {
    display: block;
    margin: 2rem 4rem;
    font-size: 1.8rem;
    text-decoration: none;
  }
  
  .menuItem:hover {
    text-decoration: underline;
  }
  
  .hamburger {
    position: fixed;
    z-index: 100;
    top: 1rem;
    right: 1rem;
    padding: 4px;
    border: black solid 1px;
    cursor: pointer;
  }
  
  .closeIcon {
    display: none;
  }
  
  .menu {
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.2s;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: black;
    color: white;
    list-style: none;
    padding-top: 4rem;
  }
  
  .showMenu {
    transform: translateY(0);
  }
  
  .burger-menu-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
  }
  
  .menu_icon_cross {
    height: 35px;
  }
  
  .burger-menu-links {
    display: flex;
    height: 80%;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding-left: 0%;
  }
  
  .link-navbar {
    color: #fff;
    font-size: 18px;
    text-decoration:none;
    text-transform: capitalize;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .burger-menu {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  
  .burger-button {
    background: none;
    border: none;
  }
  
  .burger-button-modal{
    background: none;
    border: none;
    padding-top: 42px;
    padding-left: 290px;
  }
  
  .box-logo-navbar {
    display: flex;
    justify-content: center;
  }
  
  .logo-navbar {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  