.section-qrCode {
  background-color: #161631;
  color: white;
  width: 100%;
  min-height: 100vh;
  height: auto;
}

.title_qrCode {
  font-size: 35px;
  margin-top: 0px;
  margin-bottom: 10px;
  text-align: center;
}

.box-text {
  width: 80%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.box-input-user-qrcode {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.input-user-qr-code {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 80%;
}

.liste-qrCode {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-qrCode {
  height: 30px;
  border-radius: 20px;
  background-color: white;
  margin-top: 20px;
}

.box-qrCode {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

#qrcode {
  border: 2px solid white;
}

@media (min-width: 900px) {
  .grp-all {
    display: flex;
  }
  .grp-process {
    width: 60%;
  }
}
