.section_service {
  background-image: url("../../ressources/images/background/others-mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: auto;
}
.boxs_service {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.box_card {
  width: 80%;
  display: flex;
  flex-direction: column;
  background-color: #161631;
  color: #ffff;
  margin-bottom: 40px;
  border: solid 2px white;
}

.video-container {
  width: 100%;
  height: 100px;
  min-height:150px;
  position: relative;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.box-service-texte {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card_title {
  font-size: 24px;
  font-style: oblique;
  height: 0px;
}

.trait-service {
  width: 250px;
}

.card_text {
  text-align: center;
  padding: 5px;
}
@media (min-width: 425px) {
  .section_service {
    background-image: url("../../ressources/images/background/others-desktop.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}
@media (min-width: 900px) {
  .boxs_service {
    margin-top: 5%;
    justify-content: space-evenly;
  }
  .box_card {
    width: 35%;
    flex-direction: row;
  }
  .video-container {
    width: 100%;
    height: 100%;
  }
}
