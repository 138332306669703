.button-backdown {
    display: flex;
    align-items: center;
    font-size: 20px;
    text-decoration: none;
    color: white;
    margin-left: 5%;
    padding-top: 35px;
    padding-bottom: 30px;
    border: none;
    cursor: pointer;
    background-color: transparent;
}