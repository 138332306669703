.section-detail-portfolio {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background-color: #161631;
    color: white;
}

.portfolio_detail_title {
    font-size: 35px;
    margin-top: 0px;
    margin-bottom: 10px;
    text-align: center;
}

.portfolio_detail_techno {
    font-size: 20px;
    padding-left: 5%;
}

.portfolio_detail_texte {
    padding-left: 5%;
    padding-right: 5%;
}

.portfolio_box_detail_image{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.portfolio_detail_image {
    width: 200px;
    height: 200px;
}

.box_portfolio_detail_texte_2 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

@media screen and (min-width:700px) {

    .box_portfolio_detail {
        display:flex;
        justify-content: center;
    }

    .box_portfolio_detail_texte{
        width: 60%;
    }

  
    
}