.container-nav {
  z-index: 1;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 10));
}

.logo_website {
  width: 30%;
  max-width: 120px;
}
