.section_skill {
  background-color: #161631;
  height: 100vh;
  padding-bottom: 20px;
}
.box-logos {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70%;
}
.titre-techno {
  width: 80%;
  color: white;
  font-size: 30px;
  display: flex;
  align-self: center;
}
.box-logo {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 15px;
}
.logo-skill {
  height: 60px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: linear-gradient(317deg, #a954ae, #090979 48%);
  border-radius: 20px;
  margin-bottom: 10px;
}

.img-logo-skill {
  width: 50px;
  height: 40px;
}

.title-skill {
  color: white;
}
