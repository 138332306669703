.section_contact {
  background-image: url("../../ressources/images/background/others-mobile.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  height: auto;
}

.form_contact {
  border: solid 1px white;
  background: linear-gradient(
    17deg,
    rgba(98, 77, 139, 1) 0%,
    rgba(9, 9, 121, 1) 60%
  );
  height: 600px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}

.label-form-contact {
  align-self: flex-start;
  border-bottom: solid 1px white;
  color: #eeee;
  margin-top: 10px;
  margin-left: 30px;
  margin-bottom: 5px;
}

.input {
  font-family: inherit;
  width: 90%;
  border: 0;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  outline: 0;
  padding: 7px 0;
  border-radius: 10px;
  transition: border-color 0.2s;
  padding-left: 5px;
}

.input_textarea {
  font-family: inherit;
  width: 70%;
  height: 30%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
  padding: 10px;
  margin-top: 10px;
}

.error_email {
  font-size: small;
  text-align: center;
  padding-bottom: 15px;
  color: red;
}

.button_form {
  width: 130px;
  height: 40px;
  align-self: flex-end;
  font-family: inherit;
  font-size: 20px;
  background: black;
  color: white;
  padding: 0.7em 1em;
  padding-left: 0.9em;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
  margin-right: 5%;
}

.button_form span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.button_form svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
}

.button_form:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.button_form:hover svg {
  transform: translateX(1.2em) rotate(45deg) scale(1.1);
}

.button_form:hover span {
  transform: translateX(5em);
}

.button_form:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

@media (min-width: 550px) {
  .section_contact {
    background-image: url("../../ressources/images/background/others-desktop.webp");
  }
}
