.section_mentions_legales {
    background-color: #161631;
    width: 100%;
    height: auto;
    color: white;
    padding-bottom: 30px;
}

.all-paragraph {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}
.h2-mentions-legales {
    text-decoration: underline;
}

.link-mentions-legales {
    color: aliceblue;
}

.ul-mentions-legales {
    list-style: circle;
    list-style-position: inside;
}